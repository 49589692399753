@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

/* This is the keyframe animation for opening the mobile menu */
.animate-mobile-menu.slideInRight {
    animation: slideInRight 0.3s ease-in-out forwards;
}

/* This is the keyframe animation for closing the mobile menu */
.animate-mobile-menu.slideOutRight {
    animation: slideOutRight 0.3s ease-in-out forwards;
}
