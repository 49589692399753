@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    outline: none;
}

/* Adding tailwind line clamp */

.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

/* Hide Scrolbar */
.scrollbar-hidden::-webkit-scrollbar {
    width: 5px; /* Adjust this value to match your design */
    background-color: transparent; /* Change this to the background color of your container */
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
    background-color: transparent; /* This will hide the scrollbar thumb */
}

/* For Firefox */
.scrollbar-hidden {
    scrollbar-width: thin;
}

.scrollbar-hidden::-moz-scrollbar-thumb {
    background-color: transparent; /* This will hide the scrollbar thumb in Firefox */
}

/* slide top styling */
.slide_top {
    -webkit-animation: slide-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        opacity: 0.2;
    }

    50% {
        opacity: 0.5;
    }
    80% {
        opacity: 0.8;
    }
    90% {
        opacity: 0.9;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        opacity: 0.2;
    }

    50% {
        opacity: 0.5;
    }
    80% {
        opacity: 0.8;
    }
    90% {
        opacity: 0.9;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

/* Scrollbar for Category Menu */
.custom-scrollbar {
    /* For standard scrollbar (Firefox) */
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #f5f5f5;
}
/* For WebKit browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
    width: 5px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
    border: 1px solid #9e9e9e;
}
