.main-about-section {
    display: flex;
    width: 100%;
    height: 100%;
}

.about-sidebar {
    flex: 1;
}

.about-all-elements {
    flex: 6;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.section-1 {
    box-sizing: border-box;
    padding: 10% 2%;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../../../assets/about/hero.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.section-1-icons {
    padding-bottom: 20px;
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.section-1-icons > a > svg {
    height: 20px;
    width: 20px;
    color: #00c2cb;
}

.section-1-text {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-1-text > h1 {
    color: #f5fafb;
    font-size: 2.5rem;
    font-weight: 400;
}

/* ============= Section 2 ============ */

.section-2 {
    box-sizing: border-box;
    padding: 5% 2%;
    /* background: url("../../../assets/about/bg2.png"); */
    background-size: 100%;
    background-repeat: no-repeat;
    background: #967bb6;

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 0;
    /* max-width: 1200px; */
}

.section-2-left-img {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;
    position: relative;
}

.section-2-left-img-main {
    position: relative;
}

.section-2-left-img-circle {
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.section-2-right-text-img {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.section-2-right-text-img > h1 {
    color: white;
    font-size: 28px;
    font-weight: 400;
}
.section-2-right-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin: 20px 0;
    align-items: center;
}

.section-2-right-img > img {
    width: 150px;
    height: 40px;
}

.section-2-svg1 {
    position: absolute;
    top: -100px;
    left: -100px;
}
.section-2-svg1 > img {
    width: 300px;
}
.section-2-svg2 {
    position: absolute;
    left: 23%;
    z-index: 1;
}

.section-2-svg2 > img {
    width: 350px;
}
.section-2-svg3 {
    position: absolute;
    right: 0;
}

/* ============= Section 3 ============ */

.section-3 {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;

    gap: 10px;
}

.section-3-img {
    box-sizing: border-box;
    width: 100%;
}
.section-3-img > img {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}
/* ============= Section 4 ============ */

.section-4 {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}

.section-4-div {
    box-sizing: border-box;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    background: rgba(217, 217, 217, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.section-4-div > h1 {
    font-size: 1.5em;
    font-weight: 700;
    color: #967bb6;
}
.section-4-div > img {
    width: 60%;
}

/* ============= Section 5 ============ */

.section-5 {
    box-sizing: border-box;
    padding: 5% 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-5-left-text {
    display: flex;

    justify-content: center;
    align-items: center;
    height: 100%;
}

.section-5-left-text > h1 {
    color: #6b6b6b;

    max-width: 90%;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #6b6b6b;
}

.section-5-right-all-elements {
    max-width: 60%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.section-5-right-entertainment {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: url("../../../assets/about/img51.png");
    background-size: cover;
}
.section-5-right-entertainment > p {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;

    color: #3d3d3d;
}
.section-5-right-fashion {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: url("../../../assets/about/img52.png");
    background-size: cover;
}
.section-5-right-fashion > p {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;

    color: #ffffff;
}

.section-5-right-technology {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: url("../../../assets/about/img53.png");
    background-size: cover;
}

.section-5-right-technology > p {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;

    color: #3d3d3d;
}

.section-5-right-travel {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: url("../../../assets/about/img54.png");
    background-size: cover;
}

.section-5-right-travel > p {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;

    color: #ffffff;
}

.section-5-right-business {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: url("../../../assets/about/img55.png");
    background-size: cover;
}

.section-5-right-business > p {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;

    color: black;
}

.section-5-right-wellness {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: url("../../../assets/about/img56.png");
    background-size: cover;
}

.section-5-right-wellness > p {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;

    color: #ffffff;
}

/* ============= Section 6 ============ */
.section-6 {
    box-sizing: border-box;
    width: 100%;
    background: #967bb6;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-6 > img {
    width: 150px;
}

/* ============= Section 7 ============ */

.section-7 {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    gap: 20px;
}

.section-7-card-all-elements {
    box-sizing: border-box;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 250px;
    gap: 20px;
    padding: 20px 10px;

    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); */
}
.section-7-card-img {
    min-height: 250px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.section-7-card-img > img {
    width: 100%;
}
.section-7-card-text {
    box-sizing: border-box;
    padding: 10px 0;
    min-height: 250px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.section-7-card-text > h1 {
    color: #0c9aa1;
    font-size: 1.5rem;
    font-weight: 700;
}
.section-7-card-text > p {
    /* color: rgba(61, 61, 61, 1); */
    box-sizing: border-box;
}

/* ============= Section 8 ============ */

.section-8 {
    box-sizing: border-box;
    padding: 2% 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.section-8-text {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.section-8-text > h1 {
    color: #393636;
    font-size: 30px;
}

.section-8-text > h2 {
    color: #0c9aa1;
    font-size: 26px;
}

.section-8-text > p {
    color: #6b6b6b;
}
.section-8-img {
    box-sizing: border-box;
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.section-8-img > img {
    width: 100%;
}

/* ============= Section 9 ============ */

.section-9 {
    box-sizing: border-box;
    padding: 2% 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #545454;
}

.section-9-img {
    box-sizing: border-box;
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-9-img > img {
    width: 200px;
}

.section-9-text {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.section-9-text > h1 {
    color: #00c2cb;
    font-size: 26px;
}
.section-9-text > p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: white;
}
/* ============= Section 10 ============ */

.section-10 {
    box-sizing: border-box;
    padding: 2% 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00c2cb;
}

.section-10-img-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.section-10-img-text > img {
    width: 150px;
}
.section-10-img-text > p {
    color: #393636;
    font-size: 20px;
}

/* ============= Section 11 ============ */

.section-11 {
    box-sizing: border-box;
    padding: 2% 0;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-11-all-elements {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.section-11-all-elements > p {
    font-weight: 400;
}

/* ============= Section 12 ============ */

.section-12 {
    box-sizing: border-box;
    padding: 2% 0;
    height: 100%;
    width: 100%;
    background: #545454;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-12 > h1 {
    color: #00c2cb;
    font-size: 26px;
}

.section-12 > p {
    color: white;
    font-size: 18px;
}

.section-12-feedback {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    gap: 10px;
}

.section-12-feedback > p {
    color: white;
    font-size: 20px;
}
.section-12-feedback > button {
    width: 100px;
    height: 30px;

    border: none;
    outline: none;
    background: #00c2cb;
    border-radius: 10px;
    text-align: center;
    color: white;
    cursor: pointer;
}

.section-12-call {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 10px;
}

.section-12-call > p {
    color: white;
    font-size: 20px;
}

.section-12-call-gmail {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 20px;
    color: #00c2cb;
}

.section-12-call-gmail > svg {
    font-size: 24px;
}

.section-12-call-gmail > p {
    font-size: 20px;
}

.section-12-social-icons {
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.section-12-social-icons > a > svg {
    font-size: 30px;
    color: #00c2cb;
    cursor: pointer;
    transition: 0.5s all;
}
.section-12-social-icons > svg:hover {
    color: #967bb6;
}

/* ============= Section 200 ============ */
.section-200 {
    box-sizing: border-box;
    padding: 5% 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.section-200 > h1 {
    color: #00c2cb;
    font-size: 30px;
    padding: 2% 0;
}

.section-200-card {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;

    justify-content: space-around;
}
.section-200-card-elements {
    width: 200px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.section-200-card-elements > img {
    width: 200px;
}

.section-200-card-elements > p {
    font-weight: 500;
    /* min-height: 100px; */
    font-size: 16px;
}

/* Mobile */
@media screen and (max-width: 768px) {
    .section-1 {
        padding: 10% 2%;
    }

    .section-1-text > h1 {
        font-size: 1.8rem;
    }

    .section-2 {
        padding: 5% 2%;
        flex-direction: column;
        align-items: center;
    }

    .section-2-left-img,
    .section-2-right-text-img {
        width: 100%;
    }

    .section-2-left-img-circle,
    .section-2-svg1,
    .section-2-svg2,
    .section-2-svg3 {
        display: none;
    }

    .section-2-right-img {
        flex-direction: column;
    }

    .section-5-left-text {
        width: 100%;
    }

    .section-5-right-all-elements {
        max-width: 100%;
        justify-content: center;
        padding: 10px;
    }

    .section-5-right-entertainment,
    .section-5-right-fashion,
    .section-5-right-technology,
    .section-5-right-travel,
    .section-5-right-business,
    .section-5-right-wellness {
        width: 100%;
        margin: 10px 0;
    }

    .section-5-right-entertainment > p,
    .section-5-right-fashion > p,
    .section-5-right-technology > p,
    .section-5-right-travel > p,
    .section-5-right-business > p,
    .section-5-right-wellness > p {
        font-size: 30px;
    }

    /* Adjust other sections similarly */
}
